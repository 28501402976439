/* font-family */
.sec .sec-head {
  border: 0 !important;
}

.sec .sec-head:before {
  display: none !important;
}

.sec .sec-lead {
  text-align: center;
  padding: 0 0 6% !important;
}

.sec .sec-lead p strong {
  padding: 0 0 3% !important;
}

.sec .sec-lead p span {
  display: inline-block !important;
}

.btns {
  text-align: center;
  margin: 0 -2%;
  padding: 0 0 10px;
}

.btns a {
  cursor: pointer;
  display: block;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  width: 90%;
  max-width: 300px;
  margin: auto;
  padding: 5px;
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  color: #fff;
  background-color: #369738;
  border: 3px solid #369738;
  box-sizing: border-box;
  transition: all .3s;
}

.btns a:hover {
  color: #369738;
  background-color: #fff;
}

@media screen and (min-width: 600px) {
  .sec .sec-lead {
    padding: 0 0 4% !important;
  }
  .sec .btns {
    padding: 10px;
  }
  .sec .btns a {
    font-size: 18px;
    padding: 10px;
  }
}
