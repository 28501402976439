@charset "utf-8";

@import "variables";



.sec{
	.sec-head{
		border: 0 !important;
	}
	.sec-head:before{
		display: none !important;
	}
	.sec-lead{
		text-align: center;
		padding: 0 0 6% !important;
		p{
			strong{
				padding: 0 0 3% !important;
			}
			span{
				display: inline-block !important;
			}
		}
	}
}

.btns{
	text-align: center;
	margin: 0 -2%;
	padding: 0 0 10px;
	a{
		cursor: pointer;
		display: block;
		font-family: $fontBase;
		width: 90%;
		max-width: 300px;
		margin: auto;
		padding: 5px;
		font-size: 16px;
		font-family: $fontJa;
		font-weight: 600;
		color: #fff;
		background-color: #369738;
		border: 3px solid #369738;
		box-sizing: border-box;
		transition: all .3s;
	}
	a:hover{
		color: #369738;
		background-color: #fff;
	}
}





@media screen and (min-width: 600px) {
	.sec{
		.sec-lead{
			padding: 0 0 4% !important;
		}
		.btns{
			padding: 10px;
			a{
				font-size: 18px;
				padding: 10px;
			}
		}
	}

}


@media screen and (min-width: 800px) {

}


@media screen and (min-width: 1000px) {
	
}